import React from 'react';
import { Drawer, List, ListItem, ListItemText, Box, Button, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo.png';  // Importowanie logo

const Sidebar = ({ handleLogout, isOpen, toggleSidebar }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));  // Wykrywanie urządzeń mobilnych

    return (
        <Drawer
            variant={isMobile ? 'temporary' : 'persistent'} // 'persistent' na desktopie
            open={isOpen}
            onClose={toggleSidebar}
            sx={{
                '& .MuiDrawer-paper': {
                    width: 240,
                    boxSizing: 'border-box',
                    height: '100vh',  // Pełna wysokość
                    overflowY: 'auto',  // Przewijanie w pionie
                    transition: 'width 0.3s ease-in-out',
                },
            }}
            ModalProps={{
                keepMounted: true, // Utrzymywanie sidebara zamontowanego na mobilkach
            }}
        >
            {/* Sekcja logo */}
            <Box sx={{ p: 2, textAlign: 'center' }}>
                <img src={logo} alt="Logo" style={{ maxWidth: '100%' }} />
            </Box>

            {/* Sekcja z linkami */}
            <Box sx={{ flexGrow: 1 }}>
                <List>
                    <ListItem button component={Link} to="/kontrahent">
                        <ListItemText primary="Kontrahent" />
                    </ListItem>
                    <ListItem button component={Link} to="/flota">
                        <ListItemText primary="Flota" />
                    </ListItem>
                    <ListItem button component={Link} to="/rezerwacje">
                        <ListItemText primary="Rezerwacje" />
                    </ListItem>
                    <ListItem button component={Link} to="/grafik">
                        <ListItemText primary="Grafik" />
                    </ListItem>
                    <ListItem button component={Link} to="/platnosci">
                        <ListItemText primary="Płatności" /> {/* Nowy moduł Płatności */}
                    </ListItem>
                    <ListItem button component={Link} to="/wydanie-samochodu">
                        <ListItemText primary="Wydanie Samochodu" />
                    </ListItem>
                    <ListItem button component={Link} to="/magazyn">
                        <ListItemText primary="Magazyn" />
                    </ListItem>
                    <ListItem button component={Link} to="/historia">
                        <ListItemText primary="Historia" /> {/* Zaktualizowany placeholder */}
                    </ListItem>
                    <ListItem button component={Link} to="/inne">
                        <ListItemText primary="Inne" /> {/* Placeholder na inne strony */}
                    </ListItem>
                </List>
            </Box>

            {/* Sekcja przycisku wylogowania */}
            <Box sx={{ p: 2 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<LogoutIcon />}
                    onClick={handleLogout}
                    fullWidth
                >
                    Wyloguj
                </Button>
            </Box>
        </Drawer>
    );
};

export default Sidebar;
