import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import './styles.css'; // Importuj plik CSS
import { useConfirmDelete } from './ConfirmDelete'; // Importuj hook do potwierdzenia
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

const WydanieSamochodu = ({ flota, kontrahenci, onDelete }) => {
    const [form, setForm] = useState({
        rentalAmount: '',
        mileage: '',
        fuelLevel: '',
        vehicleCondition: '',
        spareTire: false,
        firstAidKit: false,
        selectedVehicle: '',
        selectedClient: '',
    });
    const [documents, setDocuments] = useState([]);
    const [open, setOpen] = useState(false);
    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setForm({ ...form, [name]: checked });
        } else {
            setForm({ ...form, [name]: value });
        }
    };

    const generateDocx = async () => {
        const templateUrl = '/template.docx'; // Upewnij się, że ścieżka do szablonu jest poprawna
        const response = await fetch(templateUrl);

        if (!response.ok) {
            console.error('Błąd podczas pobierania szablonu:', response.statusText);
            return; // Zakończ, jeśli wystąpił błąd
        }

        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();

        const zip = new PizZip(arrayBuffer);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, lineBreaks: true });

        const selectedVehicle = flota.find(v => v.id === form.selectedVehicle) || {};
        const selectedClient = kontrahenci.find(c => c.id === form.selectedClient) || {};

        doc.setData({
            rentalAmount: form.rentalAmount || '',
            mileage: form.mileage || '',
            fuelLevel: form.fuelLevel || '',
            vehicleCondition: form.vehicleCondition || '',
            spareTire: form.spareTire ? 'Tak' : 'Nie',
            firstAidKit: form.firstAidKit ? 'Tak' : 'Nie',
            marka: selectedVehicle.marka     || '',
            model: selectedVehicle.model || '',
            registrationNumber: selectedVehicle.registrationNumber || '',
            imie: selectedClient.imie || '',
            nazwisko: selectedClient.nazwisko || '',
            nrDowodu: selectedClient.nrDowodu || '',
            pesel: selectedClient.pesel || '',
            telefon: selectedClient.telefon || '',
            adres: selectedClient.adres || '',
            currentDate: new Date().toLocaleDateString(), // Bieżąca data
        });

        try {
            doc.render();
            const out = doc.getZip().generate({ type: "blob" });

            const document = {
                id: Date.now(),
                dateGenerated: new Date().toLocaleString(),
                file: out,
            };
            setDocuments([...documents, document]);
        } catch (error) {
            console.error('Błąd podczas generowania pliku:', error);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        generateDocx();
        setOpen(false);
    };

    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć ten dokument?');
        if (confirmed) {
            setDocuments(documents.filter(doc => doc.id !== id)); // Usunięcie dokumentu z lokalnego stanu
            if (onDelete) {
                onDelete(id); // Upewnij się, że onDelete jest funkcją
            }
        }
    };

    const handleDownload = (file) => {
        saveAs(file, `Protokół_wydania_samochodu_${new Date().toISOString()}.docx`);
    };

    return (
        <Box>
            <Button variant="contained" onClick={() => setOpen(true)}>Wydaj Samochód</Button>
            
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Protokół Zdawczo-Odbiorczy</DialogTitle>
                <DialogContent>
                    <TextField
                        name="rentalAmount"
                        label="Kwota wynajmu"
                        value={form.rentalAmount}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        name="mileage"
                        label="Stan licznika"
                        value={form.mileage}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        name="fuelLevel"
                        label="Stan paliwa"
                        value={form.fuelLevel}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        name="vehicleCondition"
                        label="Stan pojazdu"
                        value={form.vehicleCondition}
                        onChange={handleInputChange}
                        fullWidth
                        multiline
                    />
                    <FormControlLabel
                        control={<Checkbox checked={form.spareTire} onChange={handleInputChange} name="spareTire" />}
                        label="Koło zapasowe"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={form.firstAidKit} onChange={handleInputChange} name="firstAidKit" />}
                        label="Apteczka"
                    />
                    
                    {/* Wybór pojazdu */}
                    <FormControl fullWidth>
                        <InputLabel>Wybierz pojazd</InputLabel>
                        <Select
                            name="selectedVehicle"
                            value={form.selectedVehicle}
                            onChange={handleInputChange}
                        >
                            <MenuItem value=""><em>-- Wybierz pojazd --</em></MenuItem>
                            {flota.map(vehicle => (
                                <MenuItem key={vehicle.id} value={vehicle.id}>
                                    {vehicle.model} - {vehicle.vin}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Wybór kontrahenta */}
                    <FormControl fullWidth>
                        <InputLabel>Wybierz kontrahenta</InputLabel>
                        <Select
                            name="selectedClient"
                            value={form.selectedClient}
                            onChange={handleInputChange}
                        >
                            <MenuItem value=""><em>-- Wybierz kontrahenta --</em></MenuItem>
                            {kontrahenci.map(client => (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.nazwisko} - {client.pesel}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Anuluj</Button>
                    <Button onClick={handleFormSubmit}>Generuj Dokument</Button>
                </DialogActions>
            </Dialog>

            {documents.length > 0 && (
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Data wygenerowania</TableCell>
                                <TableCell>Akcje</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documents.map((doc) => (
                                <TableRow key={doc.id}>
                                    <TableCell>{doc.dateGenerated}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleDownload(doc.file)}>Pobierz</Button>
                                        <Button onClick={() => handleDeleteClick(doc.id)} color="secondary">Usuń</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <ConfirmationDialog />
        </Box>
    );
};

export default WydanieSamochodu;
