import React, { useState, useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

export const useConfirmDelete = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [resolve, setResolve] = useState(() => () => {});

    const confirmDelete = useCallback((message) => {
        return new Promise((res) => {
            setMessage(message);
            setOpen(true);
            setResolve(() => res);
        });
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        resolve(true);
        handleClose();
    };

    const handleCancel = () => {
        resolve(false);
        handleClose();
    };

    const ConfirmationDialog = () => (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Potwierdzenie</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary">Anuluj</Button>
                <Button onClick={handleConfirm} color="primary">Usuń</Button>
            </DialogActions>
        </Dialog>
    );

    return { confirmDelete, ConfirmationDialog };
};
