import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Select,
    MenuItem
} from '@mui/material';
import './styles.css'; // Import the CSS file
import { useConfirmDelete } from './ConfirmDelete'; // Import the hook for confirmation

const Magazyn = ({ flota, magazyn, onAdd, onEdit, onDelete }) => {
    const [form, setForm] = useState({
        typ: '',
        opis: '',
        auto: '',
        akcje: '',
    });
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    
    // Filtracja
    const [filter, setFilter] = useState({
        typ: '',
        auto: '',
        opis: '',
    });
    const [filtersVisible, setFiltersVisible] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (editMode) {
            onEdit({ ...form, id: editId });
        } else {
            onAdd({ ...form, id: Date.now() });
        }
        setForm({
            typ: '',
            opis: '',
            auto: '',
            akcje: '',
        });
        setOpen(false);
        setEditMode(false);
        setEditId(null);
    };

    const handleEditClick = (item) => {
        setForm(item);
        setEditId(item.id);
        setEditMode(true);
        setOpen(true);
    };

    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć ten rekord?');
        if (confirmed) {
            onDelete(id);
        }
    };

    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    const filteredMagazyn = magazyn.filter(item =>
        (filter.typ === '' || item.typ === filter.typ) &&
        (filter.auto === '' || item.auto === filter.auto) &&
        (filter.opis === '' || item.opis.toLowerCase().includes(filter.opis.toLowerCase()))
    );

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>
                Magazyn
            </Typography>
            <Box className="button-group">
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj
                </Button>
                <Button 
                    variant="outlined" 
                    color="secondary" 
                    onClick={() => setFiltersVisible(!filtersVisible)}
                >
                    Filtruj
                </Button>
            </Box>

            {/* Filtry */}
            <Box className={`filter-container ${filtersVisible ? 'show' : ''}`}>
                <Select
                    name="typ"
                    value={filter.typ}
                    onChange={handleFilterChange}
                    displayEmpty
                    className="filter-field"
                >
                    <MenuItem value="">Wszystkie Typy</MenuItem>
                    <MenuItem value="uszkodzenia">Uszkodzenia</MenuItem>
                    <MenuItem value="ewidencjaOpon">Ewidencja Opon</MenuItem>
                    <MenuItem value="awarie">Awarie</MenuItem>
                </Select>
                <Select
                    name="auto"
                    value={filter.auto}
                    onChange={handleFilterChange}
                    displayEmpty
                    className="filter-field"
                >
                    <MenuItem value="">Wszystkie Auta</MenuItem>
                    {flota.map((pojazd) => (
                        <MenuItem key={pojazd.id} value={pojazd.model}>
                            {pojazd.model} ({pojazd.marka})
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    name="opis"
                    label="Opis"
                    value={filter.opis}
                    onChange={handleFilterChange}
                    className="filter-field"
                />
            </Box>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{editMode ? 'Edytuj' : 'Dodaj do Magazynu'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit} className="form-container">
                        <Select
                            name="typ"
                            value={form.typ}
                            onChange={handleInputChange}
                            displayEmpty
                            required
                            className="textfield"
                        >
                            <MenuItem value="" disabled>
                                Wybierz Typ
                            </MenuItem>
                            <MenuItem value="uszkodzenia">Uszkodzenia</MenuItem>
                            <MenuItem value="ewidencjaOpon">Ewidencja Opon</MenuItem>
                            <MenuItem value="awarie">Awarie</MenuItem>
                        </Select>
                        <TextField
                            name="opis"
                            label="Opis"
                            value={form.opis}
                            onChange={handleInputChange}
                            required
                            multiline
                            rows={4}
                            className="textfield"
                        />
                        <Select
                            name="auto"
                            value={form.auto}
                            onChange={handleInputChange}
                            displayEmpty
                            required
                            className="textfield"
                        >
                            <MenuItem value="" disabled>
                                Wybierz Auto
                            </MenuItem>
                            {flota.map((pojazd) => (
                                <MenuItem key={pojazd.id} value={pojazd.model}>
                                    {pojazd.model} ({pojazd.marka})
                                </MenuItem>
                            ))}
                        </Select>
                        {form.typ === 'uszkodzenia' && (
                            <Select
                                name="akcje"
                                value={form.akcje}
                                onChange={handleInputChange}
                                displayEmpty
                                required
                                className="textfield"
                            >
                                <MenuItem value="" disabled>
                                    Wybierz Akcję
                                </MenuItem>
                                <MenuItem value="naprawione">Naprawione</MenuItem>
                                <MenuItem value="nienaprawione">Nienaprawione</MenuItem>
                            </Select>
                        )}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {editMode ? 'Zapisz zmiany' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer component={Paper} className="table-container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Typ</TableCell>
                            <TableCell>Opis</TableCell>
                            <TableCell>Auto</TableCell>
                            <TableCell>Akcje</TableCell>
                            <TableCell>Opcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredMagazyn.map((item) => (
                            <TableRow key={item.id} className="table-row">
                                <TableCell>{item.typ}</TableCell>
                                <TableCell>{item.opis}</TableCell>
                                <TableCell>{item.auto}</TableCell>
                                <TableCell>{item.typ === 'uszkodzenia' ? item.akcje : '-'}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditClick(item)} color="primary">Edytuj</Button>
                                    <Button onClick={() => handleDeleteClick(item.id)} color="secondary">Usuń</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmationDialog />
        </Box>
    );
};

export default Magazyn;
