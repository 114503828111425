import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';  // Importowanie logo

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = () => {
        // Sprawdzenie z twardo zakodowanymi danymi
        if (username === 'admin' && password === 'admin1') {
            onLogin(true);
            navigate('/grafik'); // Przekierowanie po zalogowaniu
        } else {
            alert('Nieprawidłowy login lub hasło');
        }
    };

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            height="100vh" 
            sx={{ 
                padding: { xs: 2, sm: 4 }, 
                textAlign: 'center',
                maxWidth: '400px', // Maksymalna szerokość dla lepszej responsywności
                margin: '0 auto', // Wyśrodkowanie kontenera
                overflow: 'hidden' // Ukrywanie niepotrzebnego scrolla
            }} 
        >
            <Box mb={4}>
                <img src={logo} alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
            </Box>
            <Typography variant="h4" gutterBottom>Logowanie</Typography>
            <TextField
                label="Nazwa użytkownika"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
                fullWidth // Umożliwia pełną szerokość na mniejszych ekranach
            />
            <TextField
                label="Hasło"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                fullWidth // Umożliwia pełną szerokość na mniejszych ekranach
            />
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleLogin} 
                fullWidth // Umożliwia pełną szerokość na mniejszych ekranach
                sx={{ marginTop: 2 }} // Dodaje górny margines
            >
                Zaloguj
            </Button>
        </Box>
    );
};

export default Login;
