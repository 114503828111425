import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import './styles.css'; // Import the CSS file
import { useConfirmDelete } from './ConfirmDelete'; // Import the hook for confirmation

const Rezerwacje = ({ rezerwacje, flota, kontrahenci, onAdd, onDelete }) => {
    const [form, setForm] = useState({
        odKiedy: '',
        doKiedy: '',
        autoId: '',
        kontrahentId: ''
    });
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState({
        odKiedy: '',
        doKiedy: '',
        autoId: '',
        kontrahentId: ''
    });
    const [filtersVisible, setFiltersVisible] = useState(false);

    // Confirmation dialog
    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        onAdd({ ...form, id: Date.now() });
        setForm({ odKiedy: '', doKiedy: '', autoId: '', kontrahentId: '' });
        setOpen(false);
    };

    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć tę rezerwację?');
        if (confirmed) {
            onDelete(id);
        }
    };

    const filteredRezerwacje = rezerwacje.filter(rezerwacja =>
        (filter.odKiedy === '' || new Date(rezerwacja.odKiedy) >= new Date(filter.odKiedy)) &&
        (filter.doKiedy === '' || new Date(rezerwacja.doKiedy) <= new Date(filter.doKiedy)) &&
        (filter.autoId === '' || rezerwacja.autoId === filter.autoId) &&
        (filter.kontrahentId === '' || rezerwacja.kontrahentId === filter.kontrahentId)
    );

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>
                Rezerwacje
            </Typography>
            <Box className="button-group">
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj Rezerwację
                </Button>
                <Button 
                    variant="outlined" 
                    color="secondary" 
                    onClick={() => setFiltersVisible(!filtersVisible)}
                >
                    Filtruj
                </Button>
            </Box>

            {/* Filtry */}
            <Box className={`filter-container ${filtersVisible ? 'show' : ''}`}>
                <TextField
                    name="odKiedy"
                    label="Od Kiedy"
                    type="date"
                    value={filter.odKiedy}
                    onChange={handleFilterChange}
                    className="filter-field"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    name="doKiedy"
                    label="Do Kiedy"
                    type="date"
                    value={filter.doKiedy}
                    onChange={handleFilterChange}
                    className="filter-field"
                    InputLabelProps={{ shrink: true }}
                />
                <FormControl fullWidth className="filter-field">
                    <InputLabel>Auto</InputLabel>
                    <Select
                        name="autoId"
                        value={filter.autoId}
                        onChange={handleFilterChange}
                        label="Auto"
                    >
                        <MenuItem value="">Wszystkie Auta</MenuItem>
                        {flota.map((pojazd) => (
                            <MenuItem key={pojazd.id} value={pojazd.id}>
                                {pojazd.model} - {pojazd.marka}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth className="filter-field">
                    <InputLabel>Kontrahent</InputLabel>
                    <Select
                        name="kontrahentId"
                        value={filter.kontrahentId}
                        onChange={handleFilterChange}
                        label="Kontrahent"
                    >
                        <MenuItem value="">Wszystkie Kontrahenci</MenuItem>
                        {kontrahenci.map((kontrahent) => (
                            <MenuItem key={kontrahent.id} value={kontrahent.id}>
                                {kontrahent.imie} {kontrahent.nazwisko}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Dodaj Rezerwację</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit} className="form-container">
                        <TextField
                            name="odKiedy"
                            label="Od Kiedy"
                            type="date"
                            value={form.odKiedy}
                            onChange={handleInputChange}
                            required
                            className="textfield"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            name="doKiedy"
                            label="Do Kiedy"
                            type="date"
                            value={form.doKiedy}
                            onChange={handleInputChange}
                            required
                            className="textfield"
                            InputLabelProps={{ shrink: true }}
                        />
                        <FormControl fullWidth required>
                            <InputLabel>Auto</InputLabel>
                            <Select
                                name="autoId"
                                value={form.autoId}
                                onChange={handleInputChange}
                                label="Auto"
                            >
                                {flota.map((pojazd) => (
                                <MenuItem key={pojazd.id} value={pojazd.id}>
                                    {pojazd.marka} - {pojazd.model}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Kontrahent</InputLabel>
                            <Select
                                name="kontrahentId"
                                value={form.kontrahentId}
                                onChange={handleInputChange}
                                label="Kontrahent"
                            >
                                {kontrahenci.map((kontrahent) => (
                                <MenuItem key={kontrahent.id} value={kontrahent.id}>
                                    {kontrahent.imie} {kontrahent.nazwisko}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        Dodaj
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer component={Paper} className="table-container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Od Kiedy</TableCell>
                            <TableCell>Do Kiedy</TableCell>
                            <TableCell>Auto</TableCell>
                            <TableCell>Kontrahent</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRezerwacje.map((rezerwacja) => (
                            <TableRow key={rezerwacja.id} className="table-row">
                                <TableCell>{rezerwacja.odKiedy}</TableCell>
                                <TableCell>{rezerwacja.doKiedy}</TableCell>
                                <TableCell>
                                    {flota.find(pojazd => pojazd.id === rezerwacja.autoId)?.model || 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {kontrahenci.find(kontrahent => kontrahent.id === rezerwacja.kontrahentId)?.imie || 'N/A'}
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleDeleteClick(rezerwacja.id)} color="secondary">Usuń</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <ConfirmationDialog />
        </Box>
    );
};

export default Rezerwacje;
