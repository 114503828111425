import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
} from '@mui/material';

const Platnosci = () => {
    const [open, setOpen] = useState(false);
    const [type, setType] = useState('');
    const [form, setForm] = useState({
        ticketHistory: '',
        ticketNumber: '',
        isPaid: false,
        damageType: '',
        repairCost: '',
        invoiceStatus: '',
        paymentType: '',
        paymentMethod: '',
    });
    const [records, setRecords] = useState({
        mandaty: [],
        zniszczenia: [],
        faktura: [],
    });
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState({ open: false, id: null, recordType: '' });

    const handleTypeChange = (e) => {
        setType(e.target.value);
        setForm({
            ticketHistory: '',
            ticketNumber: '',
            isPaid: false,
            damageType: '',
            repairCost: '',
            invoiceStatus: '',
            paymentType: '',
            paymentMethod: '',
        });
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setForm({ ...form, [name]: checked });
        } else {
            setForm({ ...form, [name]: value });
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (editMode) {
            setRecords((prevRecords) => {
                const updatedRecords = { ...prevRecords };
                updatedRecords[type] = updatedRecords[type].map((record) =>
                    record.id === editId ? { ...form, id: editId } : record
                );
                return updatedRecords;
            });
        } else {
            setRecords((prevRecords) => ({
                ...prevRecords,
                [type]: [...prevRecords[type], { ...form, id: Date.now() }],
            }));
        }
        setOpen(false);
        setType('');
        setForm({
            ticketHistory: '',
            ticketNumber: '',
            isPaid: false,
            damageType: '',
            repairCost: '',
            invoiceStatus: '',
            paymentType: '',
            paymentMethod: '',
        });
        setEditMode(false);
        setEditId(null);
    };

    const handleEditClick = (record, recordType) => {
        setType(recordType);
        setForm(record);
        setEditMode(true);
        setEditId(record.id);
        setOpen(true);
    };

    const handleDeleteClick = (id, recordType) => {
        setConfirmDelete({ open: true, id, recordType });
    };

    const handleConfirmDelete = () => {
        setRecords((prevRecords) => ({
            ...prevRecords,
            [confirmDelete.recordType]: prevRecords[confirmDelete.recordType].filter((record) => record.id !== confirmDelete.id),
        }));
        setConfirmDelete({ open: false, id: null, recordType: '' });
    };

    const handleCancelDelete = () => {
        setConfirmDelete({ open: false, id: null, recordType: '' });
    };

    return (
        <Box>
            <Button variant="contained" onClick={() => setOpen(true)}>Dodaj Płatność</Button>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Dodaj Płatność</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Typ Płatności</InputLabel>
                        <Select value={type} onChange={handleTypeChange}>
                            <MenuItem value="mandaty">Mandaty</MenuItem>
                            <MenuItem value="zniszczenia">Zniszczenia</MenuItem>
                            <MenuItem value="faktura">Faktura</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Mandaty Fields */}
                    {type === 'mandaty' && (
                        <>
                            <TextField
                                name="ticketHistory"
                                label="Historia Mandatów"
                                value={form.ticketHistory}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                name="ticketNumber"
                                label="Numer Mandatu"
                                value={form.ticketNumber}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={form.isPaid} onChange={handleInputChange} name="isPaid" />}
                                label="Zapłacono"
                                sx={{ mb: 2 }}
                            />
                        </>
                    )}

                    {/* Zniszczenia Fields */}
                    {type === 'zniszczenia' && (
                        <>
                            <TextField
                                name="damageType"
                                label="Rodzaj Szkody"
                                value={form.damageType}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                name="repairCost"
                                label="Koszt Naprawy"
                                value={form.repairCost}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                        </>
                    )}

                    {/* Faktura Fields */}
                    {type === 'faktura' && (
                        <>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    name="invoiceStatus"
                                    value={form.invoiceStatus}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="paid">Opłacono</MenuItem>
                                    <MenuItem value="unpaid">Nieopłacono</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel>Rodzaj</InputLabel>
                                <Select
                                    name="paymentType"
                                    value={form.paymentType}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="invoice">Faktura</MenuItem>
                                    <MenuItem value="receipt">Paragon</MenuItem>
                                    <MenuItem value="none">Nic</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel>Sposób</InputLabel>
                                <Select
                                    name="paymentMethod"
                                    value={form.paymentMethod}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="card">Karta</MenuItem>
                                    <MenuItem value="cash">Gotówka</MenuItem>
                                    <MenuItem value="transfer">Przelew</MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Anuluj</Button>
                    <Button onClick={handleFormSubmit}>{editMode ? 'Zapisz zmiany' : 'Dodaj'}</Button>
                </DialogActions>
            </Dialog>

            {/* Tabela dla Mandatów */}
            {records.mandaty.length > 0 && (
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Historia Mandatów</TableCell>
                                <TableCell>Numer Mandatu</TableCell>
                                <TableCell>Zapłacono</TableCell>
                                <TableCell>Opcje</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {records.mandaty.map((record) => (
                                <TableRow key={record.id}>
                                    <TableCell>{record.ticketHistory || 'Brak'}</TableCell>
                                    <TableCell>{record.ticketNumber || 'Brak'}</TableCell>
                                    <TableCell>{record.isPaid ? 'Tak' : 'Nie'}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEditClick(record, 'mandaty')} color="primary">Edytuj</Button>
                                        <Button onClick={() => handleDeleteClick(record.id, 'mandaty')} color="secondary">Usuń</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Tabela dla Zniszczeń */}
            {records.zniszczenia.length > 0 && (
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Rodzaj Szkody</TableCell>
                                <TableCell>Koszt Naprawy</TableCell>
                                <TableCell>Opcje</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {records.zniszczenia.map((record) => (
                                <TableRow key={record.id}>
                                    <TableCell>{record.damageType || 'Brak'}</TableCell>
                                    <TableCell>{record.repairCost || 'Brak'}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEditClick(record, 'zniszczenia')} color="primary">Edytuj</Button>
                                        <Button onClick={() => handleDeleteClick(record.id, 'zniszczenia')} color="secondary">Usuń</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Tabela dla Faktur */}
            {records.faktura.length > 0 && (
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>Rodzaj</TableCell>
                                <TableCell>Sposób</TableCell>
                                <TableCell>Opcje</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {records.faktura.map((record) => (
                                <TableRow key={record.id}>
                                    <TableCell>{record.invoiceStatus || 'Brak'}</TableCell>
                                    <TableCell>{record.paymentType || 'Brak'}</TableCell>
                                    <TableCell>{record.paymentMethod || 'Brak'}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEditClick(record, 'faktura')} color="primary">Edytuj</Button>
                                        <Button onClick={() => handleDeleteClick(record.id, 'faktura')} color="secondary">Usuń</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Potwierdzenie usunięcia */}
            <Dialog open={confirmDelete.open} onClose={handleCancelDelete}>
                <DialogTitle>Potwierdzenie usunięcia</DialogTitle>
                <DialogContent>
                    <Typography>Czy na pewno chcesz usunąć ten rekord?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete}>Anuluj</Button>
                    <Button onClick={handleConfirmDelete} color="secondary">Usuń</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Platnosci;
