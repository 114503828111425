import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Checkbox,
    FormControlLabel,
    Grid,
    useMediaQuery,
    useTheme,
    Collapse
} from '@mui/material';
import './styles.css'; // Import the CSS file
import { useConfirmDelete } from './ConfirmDelete'; // Import the hook for confirmation

const Flota = ({ flota, onAdd, onEdit, onDelete }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [form, setForm] = useState({
        model: '',
        marka: '',
        vin: '',
        kwotaWynajmu: '',
        cenaKupna: '',
        przeglad: '',
        ubezpieczenie: '',
        numerPolisy: '',
        daneUbezpieczyciela: '',
        oc: false,
        ac: false,
        nw: false,
        historiaSerwisowa: ''
    });
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterOptions, setFilterOptions] = useState({
        model: '',
        marka: '',
        vin: '',
        kwotaWynajmu: '',
        cenaKupna: '',
        przeglad: '',
        ubezpieczenie: '',
        numerPolisy: '',
        daneUbezpieczyciela: '',
        oc: false,
        ac: false,
        nw: false,
        historiaSerwisowa: ''
    });
    const [filtersVisible, setFiltersVisible] = useState(false); // Stan do zarządzania widocznością panelu filtrów

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setForm({ ...form, [name]: checked });
        } else {
            setForm({ ...form, [name]: value });
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (editMode) {
            onEdit({ ...form, id: editId });
        } else {
            onAdd({ ...form, id: Date.now() });
        }
        resetForm();
    };

    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć ten rekord?');
        if (confirmed) {
            onDelete(id);
        }
    };

    const resetForm = () => {
        setForm({
            model: '',
            marka: '',
            vin: '',
            kwotaWynajmu: '',
            cenaKupna: '',
            przeglad: '',
            ubezpieczenie: '',
            numerPolisy: '',
            daneUbezpieczyciela: '',
            oc: false,
            ac: false,
            nw: false,
            historiaSerwisowa: ''
        });
        setOpen(false);
        setEditMode(false);
        setEditId(null);
    };

    const handleEditClick = (pojazd) => {
        setForm(pojazd);
        setEditId(pojazd.id);
        setEditMode(true);
        setOpen(true);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const handleFilterChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFilterOptions({ ...filterOptions, [name]: checked });
        } else {
            setFilterOptions({ ...filterOptions, [name]: value });
        }
    };

    const toggleFiltersVisible = () => {
        setFiltersVisible(!filtersVisible);
    };

    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    const filteredFlota = flota.filter((pojazd) => {
        const matchesSearch =
            pojazd.model.toLowerCase().includes(searchTerm) ||
            pojazd.marka.toLowerCase().includes(searchTerm) ||
            pojazd.vin.toLowerCase().includes(searchTerm) ||
            pojazd.kwotaWynajmu.toLowerCase().includes(searchTerm) ||
            pojazd.cenaKupna.toLowerCase().includes(searchTerm) ||
            pojazd.przeglad.toLowerCase().includes(searchTerm) ||
            pojazd.ubezpieczenie.toLowerCase().includes(searchTerm) ||
            pojazd.numerPolisy.toLowerCase().includes(searchTerm) ||
            pojazd.daneUbezpieczyciela.toLowerCase().includes(searchTerm) ||
            pojazd.historiaSerwisowa.toLowerCase().includes(searchTerm);

        const matchesFilters =
            (filterOptions.model ? pojazd.model.toLowerCase().includes(filterOptions.model.toLowerCase()) : true) &&
            (filterOptions.marka ? pojazd.marka.toLowerCase().includes(filterOptions.marka.toLowerCase()) : true) &&
            (filterOptions.vin ? pojazd.vin.toLowerCase().includes(filterOptions.vin.toLowerCase()) : true) &&
            (filterOptions.kwotaWynajmu ? pojazd.kwotaWynajmu.includes(filterOptions.kwotaWynajmu) : true) &&
            (filterOptions.cenaKupna ? pojazd.cenaKupna.includes(filterOptions.cenaKupna) : true) &&
            (filterOptions.przeglad ? pojazd.przeglad.includes(filterOptions.przeglad) : true) &&
            (filterOptions.ubezpieczenie ? pojazd.ubezpieczenie.includes(filterOptions.ubezpieczenie) : true) &&
            (filterOptions.numerPolisy ? pojazd.numerPolisy.includes(filterOptions.numerPolisy) : true) &&
            (filterOptions.daneUbezpieczyciela ? pojazd.daneUbezpieczyciela.includes(filterOptions.daneUbezpieczyciela) : true) &&
            (filterOptions.oc ? pojazd.oc : true) &&
            (filterOptions.ac ? pojazd.ac : true) &&
            (filterOptions.nw ? pojazd.nw : true) &&
            (filterOptions.historiaSerwisowa ? pojazd.historiaSerwisowa.toLowerCase().includes(filterOptions.historiaSerwisowa.toLowerCase()) : true);

        return matchesSearch && matchesFilters;
    });

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>
                Flota
            </Typography>

            {/* Sekcja z przyciskami */}
            <Box display="flex" justifyContent="flex-start" marginBottom={2} gap={2}>
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj pojazd
                </Button>
                <Button variant="outlined" 
                    color="secondary" onClick={toggleFiltersVisible}>
                    {filtersVisible ? 'Filtruj' : 'Filtruj'}
                </Button>
            </Box>

            {/* Sekcja filtrów z możliwością rozwijania */}
            <Collapse in={filtersVisible}>
                <Box marginBottom={2} padding={2} border={1} borderColor="grey.300">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Model"
                                variant="outlined"
                                name="model"
                                value={filterOptions.model}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Marka"
                                variant="outlined"
                                name="marka"
                                value={filterOptions.marka}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="VIN"
                                variant="outlined"
                                name="vin"
                                value={filterOptions.vin}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Kwota Wynajmu"
                                variant="outlined"
                                name="kwotaWynajmu"
                                value={filterOptions.kwotaWynajmu}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Cena Kupna"
                                variant="outlined"
                                name="cenaKupna"
                                value={filterOptions.cenaKupna}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Przegląd"
                                variant="outlined"
                                name="przeglad"
                                value={filterOptions.przeglad}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Ubezpieczenie"
                                variant="outlined"
                                name="ubezpieczenie"
                                value={filterOptions.ubezpieczenie}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Numer Polisy"
                                variant="outlined"
                                name="numerPolisy"
                                value={filterOptions.numerPolisy}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Dane Ubezpieczyciela"
                                variant="outlined"
                                name="daneUbezpieczyciela"
                                value={filterOptions.daneUbezpieczyciela}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filterOptions.oc}
                                        onChange={handleFilterChange}
                                        name="oc"
                                    />
                                }
                                label="OC"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filterOptions.ac}
                                        onChange={handleFilterChange}
                                        name="ac"
                                    />
                                }
                                label="AC"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filterOptions.nw}
                                        onChange={handleFilterChange}
                                        name="nw"
                                    />
                                }
                                label="NW"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Historia Serwisowa"
                                variant="outlined"
                                name="historiaSerwisowa"
                                value={filterOptions.historiaSerwisowa}
                                onChange={handleFilterChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>

            {/* Tabela */}
            <Box
            sx={{
                maxWidth: isMobile ? '80vw' : '100vw',
                height: isMobile ? 'auto' : '100vh',
                overflowX: isMobile ? 'auto' : 'hidden',
            }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Model</TableCell>
                            <TableCell>Marka</TableCell>
                            <TableCell>VIN</TableCell>
                            <TableCell>Kwota Wynajmu</TableCell>
                            <TableCell>Cena Kupna</TableCell>
                            <TableCell>Przegląd</TableCell>
                            <TableCell>Ubezpieczenie</TableCell>
                            <TableCell>Numer Polisy</TableCell>
                            <TableCell>Dane Ubezpieczyciela</TableCell>
                            <TableCell>OC</TableCell>
                            <TableCell>AC</TableCell>
                            <TableCell>NW</TableCell>
                            <TableCell>Historia Serwisowa</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredFlota.map((pojazd) => (
                            <TableRow key={pojazd.id}>
                                <TableCell>{pojazd.model}</TableCell>
                                <TableCell>{pojazd.marka}</TableCell>
                                <TableCell>{pojazd.vin}</TableCell>
                                <TableCell>{pojazd.kwotaWynajmu}</TableCell>
                                <TableCell>{pojazd.cenaKupna}</TableCell>
                                <TableCell>{pojazd.przeglad}</TableCell>
                                <TableCell>{pojazd.ubezpieczenie}</TableCell>
                                <TableCell>{pojazd.numerPolisy}</TableCell>
                                <TableCell>{pojazd.daneUbezpieczyciela}</TableCell>
                                <TableCell>{pojazd.oc ? 'Tak' : 'Nie'}</TableCell>
                                <TableCell>{pojazd.ac ? 'Tak' : 'Nie'}</TableCell>
                                <TableCell>{pojazd.nw ? 'Tak' : 'Nie'}</TableCell>
                                <TableCell>{pojazd.historiaSerwisowa}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" onClick={() => handleEditClick(pojazd)}>
                                        Edytuj
                                    </Button>
                                    <Button onClick={() => handleDeleteClick(pojazd.id)} color="secondary">Usuń</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer></Box>

            {/* Dialog do dodawania/edycji pojazdów */}
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>{editMode ? 'Edytuj Pojazd' : 'Dodaj Pojazd'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField name="model" label="Model" value={form.model} onChange={handleInputChange} required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="marka" label="Marka" value={form.marka} onChange={handleInputChange} required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="vin" label="VIN" value={form.vin} onChange={handleInputChange} required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="kwotaWynajmu" label="Kwota Wynajmu" value={form.kwotaWynajmu} onChange={handleInputChange} required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="cenaKupna" label="Cena Kupna" value={form.cenaKupna} onChange={handleInputChange} required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="przeglad" label="Przegląd" type="date" value={form.przeglad} onChange={handleInputChange} required fullWidth InputLabelProps={{ shrink: true }} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="ubezpieczenie" label="Ubezpieczenie" type="date" value={form.ubezpieczenie} onChange={handleInputChange} required fullWidth InputLabelProps={{ shrink: true }} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="numerPolisy" label="Numer Polisy" value={form.numerPolisy} onChange={handleInputChange} required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField name="daneUbezpieczyciela" label="Dane Ubezpieczyciela" value={form.daneUbezpieczyciela} onChange={handleInputChange} required fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox checked={form.oc} onChange={handleInputChange} name="oc" />}
                                    label="OC"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox checked={form.ac} onChange={handleInputChange} name="ac" />}
                                    label="AC"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox checked={form.nw} onChange={handleInputChange} name="nw" />}
                                    label="NW"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name="historiaSerwisowa" label="Historia Serwisowa" value={form.historiaSerwisowa} onChange={handleInputChange} required multiline rows={4} fullWidth />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {editMode ? 'Zapisz zmiany' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog />
        </Box>
    );
};

export default Flota;
