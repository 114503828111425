import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/pl'; // Importowanie polskiej lokalizacji
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const messages = {
    allDay: 'Cały dzień',
    previous: 'Poprzedni',
    next: 'Następny',
    today: 'Dziś',
    month: 'Miesiąc',
    week: 'Tydzień',
    day: 'Dzień',
    agenda: 'Harmonogram',
    noEvents: 'Brak wydarzeń',
    showMore: (total) => `+ ${total} więcej`
};

const Grafik = ({ rezerwacje = [], flota = [], kontrahenci = [] }) => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        const fetchEvents = () => {
            if (!rezerwacje || rezerwacje.length === 0) {
                setEvents([]);
                setLoading(false);
                return;
            }

            const eventsList = rezerwacje.map(rezerwacja => {
                const pojazd = flota.find(p => p.id === rezerwacja.autoId);
                const kontrahent = kontrahenci.find(k => k.id === rezerwacja.kontrahentId);

                return {
                    id: rezerwacja.id,
                    title: `Rezerwacja: ${pojazd ? pojazd.model : 'N/A'}, ${kontrahent ? kontrahent.nazwisko : 'N/A'}`,
                    start: new Date(rezerwacja.odKiedy),
                    end: new Date(rezerwacja.doKiedy),
                    allDay: false,
                    autoId: rezerwacja.autoId,
                    kontrahentId: rezerwacja.kontrahentId,
                };
            });

            setEvents(eventsList);
            setLoading(false);
        };

        fetchEvents();
    }, [rezerwacje, flota, kontrahenci]);

    const handleEventClick = (event) => {
        setSelectedEvent(event);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedEvent(null);
    };

    const selectedEventDetails = selectedEvent ? {
        pojazd: flota.find(p => p.id === selectedEvent.autoId) || {},
        kontrahent: kontrahenci.find(k => k.id === selectedEvent.kontrahentId) || {}
    } : {
        pojazd: {},
        kontrahent: {}
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Typography variant="h4" gutterBottom>Grafik Rezerwacji</Typography>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 600 }}
                views={['month', 'week', 'day']}
                messages={messages}
                onSelectEvent={handleEventClick}
            />
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Szczegóły Rezerwacji</DialogTitle>
                <DialogContent>
                    {selectedEvent ? (
                        <Box>
                            <Typography variant="h6">Rezerwacja:</Typography>
                            <Typography>Model: {selectedEventDetails.pojazd?.model || 'N/A'}</Typography>
                            <Typography>Marka: {selectedEventDetails.pojazd?.marka || 'N/A'}</Typography>
                            <Typography>Od Kiedy: {moment(selectedEvent.start).format('DD/MM/YYYY HH:mm')}</Typography>
                            <Typography>Do Kiedy: {moment(selectedEvent.end).format('DD/MM/YYYY HH:mm')}</Typography>
                            <Typography>Kontrahent: {selectedEventDetails.kontrahent?.imie || 'N/A'} {selectedEventDetails.kontrahent?.nazwisko || 'N/A'}</Typography>
                        </Box>
                    ) : (
                        <Typography>Brak danych do wyświetlenia</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Zamknij</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Grafik;
